.data-info-container {
  width: 25%;
  padding: 1rem 2rem;
  border-radius: 6px;
  background-color: var(--mid);
  text-align: center;
  h2,
  h3 {
    border-bottom: 2px solid white;
  }
}
.data-stats-container {
  ul {
    text-align: left;
    padding: 0;
    margin: 0;
  }
  li {
    p {
      font-size: 1.3rem;
      font-weight: 500;
      margin: 5px;
    }
    display: flex;
    justify-content: space-between;
  }
}
.data-main-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.data-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dates {
  display: flex;
  justify-content: center;
  align-items: center;
  .col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 4rem;
    margin: 0rem 2rem;
    h3 {
      margin: 0;
    }
    h2 {
      margin: 0;
    }
  }
  .set-end-date input {
    margin-right: 1rem;
  }
}
.DATA-date-picker {
  display: flex;
  align-items: center;
  button {
    cursor: pointer;
  }
}

.entries-table {
  margin: auto;
  th,
  td {
    padding: 5px 1rem 5px 1.5rem;
    &:nth-last-child(1) {
      padding: 5px 2px;
    }
  }
  th {
    background-color: var(--secondary);
  }
  tr {
    &:nth-child(odd) {
      background-color: var(--mid);
    }
  }
  #fa-pen-to-square {
    color: var(--yellow);
  }
  #fa-trash {
    color: var(--red);
  }
  .fa {
    margin: 0rem 15px;
    cursor: pointer;
  }
  .entries-td-icons {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.entry-modal {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;

  .entry-modal-body {
    background-color: var(--secondary);
    z-index: 13;
    padding: 3rem 7rem;
    border-radius: 0.6rem;
    font-size: 1.6rem;
    // body display stuff
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h4,
    h3 {
      margin: 10px;
    }
    h4 {
      padding: 5px 10px;
      border-radius: 5px;
      border: 3px solid white;
    }
    button {
      border: none;
      border-radius: 5px;
      padding: 10px;
      font-size: 1rem;
      margin: 2px 10px;
      cursor: pointer;
      margin: 10px;
    }
  }
  .screen {
    background-color: black;
    position: absolute;
    z-index: 12;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
}
#edit-entry {
  #edit-back-btn {
    margin-left: 1rem;
  }
}

@media (max-width: 1024px) {
  .data-main-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .data-info-container {
    width: 100%;
    margin-bottom: 1rem;
    padding: 1vw;
  }
  .data-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .data-section {
    h1 {
      border-bottom: 3px solid white;
    }
  }

  .entries-table {
    width: 100%;
    margin: 0;
    th,
    td {
      padding: 1px 3px 1px 3px;
    }
    .fa {
      margin: 0px;
    }
  }
}
