$break-lrg: 1024px;
/* Main title ("Logbook") */
@mixin magic-border($width, $color, $duration, $direction) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: calc(100% + #{$width * 2});
    height: calc(100% + #{$width * 2});
    top: calc(#{$width}/ -1);
    left: calc(#{$width}/ -1);
    background: linear-gradient(to right, $color 0%, $color 100%),
      linear-gradient(to top, $color 50%, transparent 50%),
      linear-gradient(to top, $color 50%, transparent 50%),
      linear-gradient(to right, $color 0%, $color 100%),
      linear-gradient(to left, $color 0%, $color 100%);
    background-size: 100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
    background-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
    background-repeat: no-repeat, no-repeat;
    transition: transform $duration ease-in-out,
      background-position $duration ease-in-out,
      background-size $duration ease-in-out;
    transform: scaleX(0) rotate(180deg * $direction);
    transition-delay: $duration * 2, $duration, 0s;
  }
  &:hover {
    &:before {
      background-size: 200% $width, $width 400%, $width 400%, 55% $width,
        55% $width;
      background-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
      transform: scaleX(1) rotate(180deg * $direction);
      transition-delay: 0s, $duration, $duration * 2;
    }
  }
}

@mixin magic-border-mask($width, $duration, $background, $direction) {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: calc(100% + #{$width * 2});
    height: calc(100% + #{$width * 2});
    top: calc(#{$width}/ -1);
    left: calc(#{$width}/ -1);
    mask: linear-gradient(to right, #000 0%, #000 100%),
      linear-gradient(to top, #000 50%, transparent 50%),
      linear-gradient(to top, #000 50%, transparent 50%),
      linear-gradient(to right, #000 0%, #000 100%),
      linear-gradient(to left, #000 0%, #000 100%);
    mask-size: 100% $width, $width 200%, $width 200%, 0% $width, 0% $width;
    mask-position: 50% 100%, 0% 0%, 100% 0%, 100% 0%, 0% 0%;
    mask-repeat: no-repeat, no-repeat;
    transition: transform $duration ease-in-out,
      mask-position $duration ease-in-out, mask-size $duration ease-in-out;
    transform: scaleX(0) rotate(180deg * $direction);
    transition-delay: $duration * 2, $duration, 0s;
    background: $background;
  }
  &:hover {
    &:before {
      mask-size: 200% $width, $width 400%, $width 400%, 55% $width, 55% $width;
      mask-position: 50% 100%, 0% 100%, 100% 100%, 100% 0%, 0% 0%;
      transform: scaleX(1) rotate(180deg * $direction);
      transition-delay: 0s, $duration, $duration * 2;
    }
  }
}

// Navbar
.nav {
  border-bottom: 1px solid white;
  display: flex;
  justify-content: space-between;
}
.nav-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  font-size: 1.5rem;
  font-weight: 600;
}
.nav-list-item > a {
  text-decoration: none;
  color: var(--text-colour);
}
/* Main title (Logbook) */
@media (min-width: $break-lrg) {
  .nav-title {
    @include magic-border(3px, var(--green), 0.3s, 0);
  }
}
/* Active link underline */
@media (min-width: $break-lrg) {
  .active-link:after {
    content: "";
    display: block;
    margin: auto;
    height: 3px;
    width: 100%;
    background: var(--green);
    animation: expand 0.5s ease;
    //   transition: width 0.5s ease;
  }
}

/* KEYFRAMES */
/* Active link */
@keyframes expand {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

@media (max-width: $break-lrg) {
  .nav-list {
    width: 50%;
  }
  .active-link {
    border-bottom: 3px solid var(--green);
  }
}
