// Form
.form-container {
  // box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  background: rgb(28, 26, 50);
  padding: 1rem;
  margin-top: 2rem;
}
.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .form-title {
    margin: 0 0 1rem 0;
  }
  p {
    font-size: 1.2rem;
    span {
      font-weight: bold;
    }
  }
}
// Inputs
.form-inputs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  .start-odometer,
  .end-odometer,
  .reason-input-container,
  .date-input-container {
    width: 23%;
    display: flex;
    justify-content: center;
    input {
      width: 100%;
    }
  }
  .date-input-container {
    > div {
      display: flex;
      justify-content: center;
      > div {
        display: flex;
        justify-content: center;
      }
    }
  }
  .submit-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    button {
      cursor: pointer;
    }
    .second-trip {
      display: flex;
      align-items: center;
      #secondTrip {
        transform: scale(1.5);
        margin-left: 1rem;
      }
    }
    .buttons,
    .draft-btns {
      display: flex;
      flex-wrap: wrap;
      button {
        margin-left: 1rem;
      }
    }
  }
}
.tooltip {
  position: relative;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}
.tooltip-text {
  visibility: hidden;
  background-color: var(--yellow);
  color: var(--dark);
  border-radius: 6px;
  padding: 5px;
  text-align: center;
  // pos
  position: absolute;
  z-index: 1;
  top: -70%;
}
// Date
.custom-date-input {
  cursor: pointer;
  width: 90%;
}

@media (max-width: 1024px) {
  .form-inputs {
    .start-odometer,
    .end-odometer,
    .reason-input-container,
    .date-input-container {
      margin-bottom: 1rem;
      width: 23%;
      display: flex;
      justify-content: center;
      input {
        width: 100%;
        font-size: 1.3rem;
      }
    }
    .date-input-container {
      width: 100%;
      > div {
        display: flex;
        justify-content: center;
        > div {
          display: flex;
          justify-content: center;
        }
      }
    }
    .start-odometer,
    .end-odometer {
      width: 47%;
    }
    .reason-input-container {
      width: 100%;
    }
    .submit-container {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 1rem;
      button {
        cursor: pointer;
      }
      .buttons,
      .draft-btns {
        width: 100%;
        justify-content: flex-end;
        button {
          margin-left: 0rem;
          margin-bottom: 5px;
          width: 90%;
        }
      }
      .form-submit-btn {
        width: 90%;
      }
    }
  }
}
