:root {
  --dark: rgb(25, 25, 48);
  --mid: rgb(36, 42, 68);
  --secondary: rgb(45, 56, 92);
  --grey-med: #d3d0cb;
  --grey-light: #e7e5df;
  --green: #44bba4;
  --yellow: #e7bb41;
  --red: #e74141;
  --text-colour: white;
  --page-margin-desktop: 3vh 10vw 0 10vw;
  --page-margin-mobile: 3vh 5vw 0 5vw;
  --button-padding: 0.7rem;
  --button-border-radius: 8px;
  --button-background-colour: var(--green);
  --font-default: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  --font-roboto: "Roboto", sans-serif;
  --font-open-sans: "Open Sans", sans-serif;
}
body {
  // font-family: var(--font-default);
  // font-family: var(--font-roboto);
  font-family: var(--font-open-sans);
}
.btn,
.back-btn {
  font-family: var(--font-default);
  padding: var(--button-padding);
  border-radius: var(--button-border-radius);
  border: none;
  background-color: var(--button-background-colour);
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  color: black;
}
.back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--yellow);
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--dark);
  color: var(--text-colour);
}
h1 {
  font-size: 2rem;
  font-weight: 600;
}

// Main container
.main-container {
  margin: var(--page-margin-desktop);
}

// Notification
.notification {
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  padding: 2rem 5rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  opacity: 100%;
  animation: easeOut 6s;
  animation-fill-mode: forwards;
}

@keyframes easeOut {
  0% {
    opacity: 0%;
  }
  10% {
    opacity: 100%;
  }
  90% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

.red {
  background-color: var(--red);
}
.yellow {
  background-color: var(--yellow);
}
.green {
  background-color: var(--green);
}

@media (max-width: 1024px) {
  .main-container {
    margin: var(--page-margin-mobile);
  }
}
