// Form
.login-container,
.signup-container {
  margin: auto;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  background: rgb(28, 26, 50);
  padding: 1rem;
  margin-top: 2rem;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-title,
.signup-title {
  font-size: 2.4rem;
  margin: 1rem 0rem;
  text-decoration: underline;
}
// Inputs
.login-form,
.signup-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  input {
    display: inline-block;
    padding: 10px 15px;
    font-size: 20px;
    border-radius: 0;
    -webkit-appearance: none;
    border: 1px solid transparent;
    margin-top: 1rem;
  }
  button {
    margin-top: 1rem;
    display: inline-block;
    padding: 10px 15px;
    font-size: 20px;
    border-radius: 0;
    -webkit-appearance: none;
    border: 1px solid transparent;
    background-color: var(--green);
    color: black;
    cursor: pointer;
  }
}
// Button container
.login-buttons {
  display: flex;
  flex-direction: column;
  //   width: 100%;
  margin: 1rem 0rem;
}
.login-to-signup,
.signup-to-login {
  margin-top: 1rem;
  a {
    color: var(--green);
    font-weight: 600;
  }
}

/* headlines with lines */
.decorated {
  overflow: hidden;
  text-align: center;
}
.decorated > span {
  position: relative;
  display: inline-block;
}
.decorated > span:before,
.decorated > span:after {
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 2px solid;
  width: 100vw;
  margin: 0 20px;
}
.decorated > span:before {
  right: 100%;
}
.decorated > span:after {
  left: 100%;
}
